
#tooltip {
  cursor: pointer;
  display: inline-block;
  background-image: url('/pic/white-question.svg');
  width: 25px;
  height: 25px;
  margin-left: 2px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%;
  position: relative;
  &:hover {
    background-image: url('/pic/white-question-hover.svg');
  }
}
.tippy-box {
  background-color: #fff;
  color: #1C75BC;
  box-shadow: 0 1.6rem 2.4rem rgba(0, 0, 0, 0.08), 0 0.2rem 0.6rem rgba(0, 0, 0, 0.04), 0 0 0.1rem rgba(0, 0, 0, 0.04);
  transition: box-shadow ease-in-out 0.5s;
  border-color: #fff;
  font-weight: 700;
  border-radius: 3rem;
  padding: 1.2rem 1.2rem;
}
.tippy-box > .tippy-arrow:before {
  content: "";
  position: absolute;
  left: 0;
  border-bottom-color: #fff;
}
.form-item {
  margin-top: 1rem;
}
