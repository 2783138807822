
#acel-tutors-carousel {
  @media (max-width: 768px) {
    .section__coaches-carousel-region span {
      padding-left: 15px;
      background: none!important;
      font-size: 12px!important;
    }
    .section__coaches-item {
      height: 300px!important;
    }
    .owl-item {
      width: 200px!important;
    }
  }
}
