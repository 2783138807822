
#tutor-modal {
  #tooltip {
    cursor: pointer;
    margin-bottom: -15px;
  }
  .comment {
    max-width: 540px;
    flex-basis: 50%;
    margin-left: 50px;
    margin-bottom: 25px;
    padding-left: 53px;
  }
  .comment_bubble {
    padding: 15px;
    max-width: 470px;
  }
  .comment_bubble {
    background: #ebebeb;
    position: relative;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    margin-left: 50px;
  }
  .comment_bubble:before {
    content: "";
    position: absolute;
    right: 100%;
    top: 25px;
    width: 0;
    height: 0;
    border-top: 12px solid transparent;
    border-right: 13px solid #ebebeb;
    border-bottom: 12px solid transparent;
  }
  #modal-tutor-close {
    display: block;
    top: 2.4rem;
    position: absolute;
    right: 2.4rem;
    z-index: 10000;
  }
  .modal-header {
    padding: 2.4rem 0 0;
  }
  .modal-xxg {
    max-width: 720px;
    zoom: 150%;
    margin-top: -10%;
  }
  .avatar_small {
    width: 5.6rem;
    height: 5.6rem;
    background-size: cover;
  }
  ul {
    list-style: none;
    font-size: 1rem;
    padding: 0;
    li {
      padding: 0.7rem 1rem 0;
      &::before {
        width: 0.6rem;
        height: 0.6rem;
        border-radius: 50%;
        background-color: #ED1B2F;
        content: "";
        display: inline-block;
        margin-left: -1rem;
        margin-right: 0.5rem;
      }
    }
    &.blue-ball {
      li {
        &::before {
          background-color: #002E5E;
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    .modal-body {
      zoom: 160%;
    }
    .modal-xxg {
      margin-top: -50%;
    }
  }
}
